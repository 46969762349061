import React, { useState } from 'react';
import { change, getFormValues, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { INITIAL_STATE_ITEM_FORM_NAME as FORM_NAME } from './formTypes';
import renderInput from './renderers/renderInput';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ImageChoose from './ImageChoose';
import FormButtons from './FormButtons';
import { useTranslation } from 'react-i18next';
import renderSelect from './renderers/renderSelect';
import { getThumbUrlById } from '../utils/mediaUtils';
import GoogleMapLocationForm from '../gmap/GoogleMapLocationForm';
import { isPointWithinRadius } from 'geolib';
import GoogleMapObjectForm from '../gmap/GoogleMapObjectForm';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ModalEdit from '../utils/ModalEdit';
import QrCodeForm from '../qr-codes/QrCodeForm';

const selector = formValueSelector(FORM_NAME);

const OfferObjectForm = ({ error, scenes, gameSpec, formValues, dispatch, handleSubmit, onSubmit, handleCancel, handleDelete, initialValues, limited, logoId, game }) => {
  const { t, i18n } = useTranslation();
  const { offerId } = formValues || {};
  const spec = gameSpec.spec && gameSpec.spec.resources && gameSpec.spec.resources.offers.find((i) => i.id === offerId);
  const [openEditQrCodeModal, setOpenEditQrCodeModal] = useState(false);
  const closeEditQrCodeModal = () => {
    setOpenEditQrCodeModal(false);
  };
  const renderInputField = (payloadId) => {
    const fieldSpec = spec.payloadSpec[payloadId];
    if (fieldSpec.type === 'string' || fieldSpec.type === 'email') {
      return <Field key={payloadId} name={`payload.${payloadId}`} label={fieldSpec.label} type='text' component={renderInput} />;
    } else if (fieldSpec.type === 'number') {
      return <Field key={payloadId} name={`payload.${payloadId}`} label={fieldSpec.label} type='number' component={renderInput} />;
    } else if (fieldSpec.type === 'text') {
      return <Field key={payloadId} name={`payload.${payloadId}`} label={fieldSpec.label} type='textarea' component={renderInput} />;
    } else if (fieldSpec.type === 'image') {
      return (
        <React.Fragment key={payloadId}>
          <Field
            name={`payload.${payloadId}.id`}
            label={fieldSpec.label}
            logoIdToShow={formValues.payload && formValues.payload[payloadId] && formValues.payload[payloadId].id}
            handleRemoveMedia={() => {
              dispatch(change(FORM_NAME, `payload.${payloadId}`, null));
            }}
            gameId={game.id}
            imageWidth={300}
            handleSetMediaId={(newId) => {
              dispatch(change(FORM_NAME, `payload.${payloadId}.id`, newId));
              dispatch(change(FORM_NAME, `payload.${payloadId}.type`, 'image'));
              dispatch(change(FORM_NAME, `payload.${payloadId}.uri`, getThumbUrlById(newId, false, 800, 0)));
            }}
            component={ImageChoose}
          />
          <Field name={`payload.${payloadId}.type`} type='hidden' value='image' component='input' />
        </React.Fragment>
      );
    } else {
      return `Type ${fieldSpec.type} not yet supported`;
    }
  };
  const myOnSubmit = (formValues) => {
    onSubmit(formValues);
  };

  const currentOfferSpec = () => {
    if (!spec) {
      return '';
    }
    return Object.keys(spec.payloadSpec || {}).map((payloadId) => renderInputField(payloadId));
  };

  const getScenesItemList = () => {
    return scenes.map((scene) => {
      return (
        <option key={scene.id} value={scene.id}>
          {scene.name}
        </option>
      );
    });
  };

  const getAvailableOffersList = () => {
    if (gameSpec.spec.resources.offers.length === 0) {
      return null;
    }
    return gameSpec.spec.resources.offers.map((item) => {
      //const itemId = item.id;
      return (
        <option key={item.id} value={item.id}>
          {item.title}
        </option>
      );
    });
  };
  const onMarkerDrag = (lat, lng) => {
    const scene = scenes.find((scene) => isPointWithinRadius({ latitude: lat, longitude: lng }, scene.place.center, scene.place.radius));
    dispatch(change(FORM_NAME, 'sceneId', scene?.id ?? ''));
    dispatch(change(FORM_NAME, 'location.latitude', lat));
    dispatch(change(FORM_NAME, 'location.longitude', lng));
  };
  if (!gameSpec || !gameSpec.spec || !gameSpec.spec.resources || !gameSpec.spec.resources.offers) {
    return <>Loading....</>;
  }

  return (
    <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
      <Row>
        <Col className='col-sm-12 col-lg-6'>
          <Field name={'name'} label={'System Name'} type='text' component={renderInput} />
          <Field
            name='sceneId'
            component={renderSelect}
            label='Scene'
            noFormGroup={true}
            onChange={(e) => {
              const scene = scenes.find((s) => s.id === e.target.value);
              if (!scene) {
                return;
              }

              dispatch(change(FORM_NAME, `location`, { latitude: scene.place.center.latitude, longitude: scene.place.center.longitude }));
            }}>
            <option key='select-item' value={''}>
              -- Select one --
            </option>
            {getScenesItemList()}
          </Field>

          {(initialValues?.offerId || initialValues.offerId === '0') && (
            <Field
              name='offerId'
              component={renderSelect}
              label='Offer'
              hidden={true}
              noFormGroup={true}
              onChange={() => {
                dispatch(change(FORM_NAME, `payload`, {}));
                const scene = scenes.find((s) => s.id === formValues.sceneId);
                dispatch(change(FORM_NAME, `location`, scene ? { latitude: scene.lat, longitude: scene.lng } : {}));
              }}>
              <option key='select-item'>-- Select one --</option>
              {getAvailableOffersList()}
            </Field>
          )}
          {formValues?.id && (
            <div className='d-flex pt-4 full-width'>
              <OverlayTrigger
                placement={'top'}
                overlay={
                  <Tooltip id={`qrTooltip}`}>
                    A QR code that when scanned, simulates a contact of the player with the current object. The object behaviour defined under "When Contacted" will be triggered.
                  </Tooltip>
                }>
                <Button variant='outline-secondary' block onClick={() => setOpenEditQrCodeModal(true)}>
                  <i className='fas fa-qrcode'></i> Direct Object Contact QR code
                </Button>
              </OverlayTrigger>
              <ModalEdit width={60} openEditModal={openEditQrCodeModal} closeEditModal={closeEditQrCodeModal} heading={'Get QR Code'} portal={'milestone'}>
                <QrCodeForm
                  eventType='de'
                  eventName={`Contact_${formValues?.offerId}`}
                  closeEditQrCodeModal={closeEditQrCodeModal}
                  gameId={game.id}
                  sceneId={formValues?.sceneId}
                  objectId={formValues?.id}
                />
              </ModalEdit>
            </div>
          )}
          {currentOfferSpec()}
        </Col>
        <Col className='col-sm-12 col-lg-6'>
          <div className='row mt-3 mt-md-0'>
            <div className='col-6'>
              <Field name='location.latitude' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Latitude' type='number' step='0.001' component={renderInput} />
            </div>
            <div className='col-6'>
              <Field name='location.longitude' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Longitude' type='number' step='0.001' component={renderInput} />
            </div>
          </div>
          <GoogleMapObjectForm
            formName={FORM_NAME}
            noRadius={true}
            lat={formValues?.location?.latitude ?? gameSpec?.pg?.place?.lat}
            lng={formValues?.location?.longitude ?? gameSpec?.pg?.place?.lng}
            onMarkerDrag={onMarkerDrag}
            style={{ height: '600px' }}
            inScenes={scenes}
          />
        </Col>
      </Row>

      <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id ? handleDelete : null} />
    </Form>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name || formValues.name === '') {
    errors.name = 'System name is required';
  }

  if (!formValues.sceneId || formValues.sceneId === '') {
    errors.sceneId = 'Select a scene';
  }

  if (!formValues.archetypeId || formValues.archetypeId === '') {
    errors.archetypeId = 'Select an object type';
  }
  return errors;
};

export default connect((state, ownProps) => {
  return {
    formValues: getFormValues(FORM_NAME)(state),
  };
})(
  reduxForm({
    form: FORM_NAME,
    validate: validate,
  })(OfferObjectForm)
);
