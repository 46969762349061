import React from 'react';

import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import GoogleMapLocationForm from '../gmap/GoogleMapLocationForm';
import { SCENE_FORM_NAME as FORM_NAME } from './formTypes';
import renderInput from './renderers/renderInput';
import ImageChoose from './ImageChoose';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormButtons from './FormButtons';

import { g4mFormValidate, g4mFormTotalFormValidate, asyncValidateForm } from '../utils/validationUtils';
import RichTextEditorWYSIWYG from './renderers/RichTextEditorWYSIWYG';
import { useTranslation } from 'react-i18next';
import renderCheckbox2 from './renderers/renderCheckbox2';

const selector = formValueSelector(FORM_NAME);

const SceneForm = ({ error, game, dispatch, handleSubmit, onSubmit, handleCancel, initialValues, logoId, handleDelete }) => {
  const { t } = useTranslation();

  const onMarkerDrag = (lat, lng) => {
    dispatch(change(FORM_NAME, 'lat', lat));
    dispatch(change(FORM_NAME, 'lng', lng));
  };
  const myOnSubmit = (formValues) => {
    onSubmit(formValues);
  };

  return (
    <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
      <Row>
        <Col>
          <Field name='name' label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />
          <Field name='title' label='Title' asCol={false} component={renderInput} />
          <Field asCol={false} name='notes' type='textarea' label='Notes' tooltip={t('NOTES_FIELD_TOOLTIP')} component={renderInput} />
          <Field inline={true} component={renderCheckbox2} name={`enabled`} type='checkbox' label={`This scene is initially enabled`} props={{}} />
          {game.pg?.type === 'map' && (
            <Field
              inline={true}
              component={renderCheckbox2}
              name={`fgOnly`}
              type='checkbox'
              label={`Foreground only`}
              tooltip={'Players cross this scene ONLY IF they have the app open and foregrounded'}
              props={{}}
            />
          )}
        </Col>
        {game.pg?.type === 'image' && (
          <Col lg='4'>
            <Field
              name='logoId'
              handleRemoveMedia={() => dispatch(change(FORM_NAME, 'logoId', null))}
              imageWidth={300}
              required={true}
              logoIdToShow={logoId}
              handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'logoId', newId))}
              label='Background Image'
              gameId={game?.id}
              asCol={false}
              component={ImageChoose}
            />
          </Col>
        )}
      </Row>
      {game.pg?.type === 'map' && (
        <Row className='mt-3'>
          <Col lg={12}>
            <GoogleMapLocationForm style={{ height: '500px' }} formName={FORM_NAME} lat={initialValues.lat} radius={initialValues.radius} lng={initialValues.lng} onMarkerDrag={onMarkerDrag} />
            <Form.Row>
              <Field asCol={true} name='lat' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Latitude' type='number' step='0.001' component={renderInput} />
              <Field asCol={true} name='lng' normalize={(value, previousValue, allValues) => (value ? parseFloat(value) : 0)} label='Longitude' type='number' step='0.001' component={renderInput} />
              <Field asCol={true} name='radius' label='Radius (m)' type='number' step='10' component={renderInput} />
            </Form.Row>
          </Col>
        </Row>
      )}

      <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id ? handleDelete : null} />
    </Form>
  );
};

const validate = (formValues, donotknow) => {
  const errors = {};
  g4mFormValidate(errors, formValues, ['title', 'radius']); // name is validated asynchronously
  g4mFormTotalFormValidate(errors);
  //console.log('Scene Form validation errors: ', errors);
  return errors;
};

// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
//  })(GameForm);

export default connect((state, ownProps) => {
  return {
    logoId: selector(state, 'logoId'),
    gameId: selector(state, 'gameId'),
  };
})(
  reduxForm({
    form: FORM_NAME,
    validate: validate,
    asyncValidate: asyncValidateForm,
    asyncBlurFields: ['name'],
  })(SceneForm)
);
