import gamesApi from '../apis/gamesApi';
import history from '../history';

import { displayError, displaySuccess } from '../components/utils/notifications';
import { validateCoords } from '../utils/utils';
import { DELETE_GAME, FETCH_MY_GAMES, FETCH_GAME, FETCH_GAME_ANALYTICS, CREATE_GAME, EDIT_GAME, GAME_LOADING } from './types';

export const deleteGame = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .delete(`/api/design/games/${id}`)
      .then((response) => {
        dispatch({ type: DELETE_GAME, payload: id });
        history.push('/');
        displaySuccess(`Game deleted`, `Game ${id} was deleted`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error deleting game ${id}`);
        reject();
      });
  });
};

export const cloneGame = (id) => async (dispatch) => {
  // based on https://www.firehydrant.io/blog/graceful-error-handling-with-redux/, we return a promise
  // so the calling handler can catch failed calls

  return new Promise((resolve, reject) => {
    gamesApi
      .post(`/api/design/games/${id}/clone`)
      .then((response) => {
        displaySuccess(`Game cloned`, `Game has been cloned successfuly`);
        dispatch(fetchMyGames());
        resolve();
      })
      .catch((err) => {
        // console.log(JSON.stringify(err));
        displayError(err, `Error cloning game ${id}`);
        reject();
      });
  });
};

export const fetchGame = (id) => async (dispatch) => {
  //console.log('skata 1');
  // const response = await gamesApi.get(`/api/design/games/${id}`);
  // dispatch({type:FETCH_GAME, payload:response.data});
  return new Promise((resolve, reject) => {
    gamesApi
      .get(`/api/design/games/${id}`)
      .then((response) => {
        dispatch({ type: FETCH_GAME, payload: response.data });
        //displaySuccess('game fetched');
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error retrieving game data for id ${id}`);
        reject();
      });
  });
};

export const fetchGameAnalytics = (gameVersionId, includeAbandoned) => async (dispatch) => {
  let endpoint = `/api/analytics/${gameVersionId}`;
  if (includeAbandoned === true) {
    endpoint += `?includeAbandoned=true`;
  }
  return new Promise((resolve, reject) => {
    gamesApi
      .get(endpoint)
      .then((response) => {
        dispatch({
          type: FETCH_GAME_ANALYTICS,
          payload: response.data.analytics,
        });
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error retrieving game analytics for id ${gameVersionId}`);
        //reject();
        resolve();
      });
  });
};

export const fetchMyGames = () => async (dispatch, getState) => {
  const userId = getState().auth.user.id;
  if (userId) {
    //const response = await gamesApi.get(`/api/design/games?userId=${userId}`);
    // const response = await gamesApi.get(`/api/design/games`);
    // dispatch({type: FETCH_MY_GAMES, payload: response.data});

    return new Promise((resolve, reject) => {
      dispatch({ type: GAME_LOADING, payload: true });
      gamesApi
        .get(`/api/design/games`)
        .then((response) => {
          dispatch({ type: FETCH_MY_GAMES, payload: response.data });
          resolve();
        })
        .catch((err) => {
          displayError(err, `Error retrieving user games`);
          reject();
        })
        .finally(() => {
          dispatch({ type: GAME_LOADING, payload: false });
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      dispatch({ type: FETCH_MY_GAMES, payload: [] });
      displayError(null, `Not loged in`);
      reject();
    });
  }
};
export const editGame = (id, formValues) => async (dispatch) => {
  //// console.log('------ '+ (typeof formValues.lat));
  //// console.log('++++++++'+ (typeof formValues.lng));
  validateCoords(formValues, false);

  // const response = await gamesApi.patch(`/api/design/games/${id}`, formValues);
  // //console.log(response.data);
  // dispatch({type:EDIT_GAME, payload:response.data});

  return new Promise((resolve, reject) => {
    gamesApi
      .patch(`/api/design/games/${id}`, formValues)
      .then((response) => {
        dispatch({ type: EDIT_GAME, payload: response.data });
        displaySuccess(`Game ${response.data.title} saved`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error saving game ${id}`);
        reject();
      });
  });
};

export const unpublishGame = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .post(`/api/design/games/${id}/unpublish`)
      .then((response) => {
        dispatch(fetchMyGames());
        displaySuccess(`Success`, `Game Unpublished!`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error unpublishing game ${id}`);
        reject();
      });
  });
};

export const archiveGame = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .post(`/api/design/games/${id}/archive`)
      .then((response) => {
        dispatch(fetchMyGames());
        displaySuccess(`Success`, `Game Archived!`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error while archiving game ${id}`);
        reject();
      });
  });
};

export const editInstancePlayingPeriod = (id, formValues) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .patch(`/api/manage/gameInstances/${id}/playingPeriod`, formValues)
      .then((response) => {
        displaySuccess(`Playing period updated`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error saving playing period`);
        reject();
      });
  });
};
export const terminateInstance = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .post(`/api/instances/${id}/terminate`)
      .then((response) => {
        displaySuccess(`Game instance terminated successfully`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error terminating game instance`);
        reject();
      });
  });
};
export const deleteInstance = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .delete(`/api/instances/${id}`)
      .then((response) => {
        displaySuccess(`Game instance deleted successfully`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error deleting game instance`);
        reject();
      });
  });
};

export const editGameVersionPlayingPeriod = (gameVersionId, formValues) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .patch(`/api/game-versions/${gameVersionId}/playingPeriod`, formValues)
      .then((response) => {
        displaySuccess(`Playing period updated`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error saving playing period for game version ${gameVersionId}`);
        reject();
      });
  });
};

export const terminateAllGameInstances = (gameId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .post(`/api/design/games/${gameId}/terminateAllInstances`)
      .then((response) => {
        displaySuccess(`All instances deleted successfully`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error deleting instances of game ${gameId}`);
        reject();
      });
  });
};

export const sendMessage =
  (gameId, body = {}) =>
  async (dispatch) => {
    return new Promise((resolve, reject) => {
      gamesApi
        .post(`/api/design/games/${gameId}/sendMessage`)
        .then((response) => {
          displaySuccess(`All instances deleted successfully`);
          resolve();
        })
        .catch((err) => {
          displayError(err, `Error sending message`);
          reject();
        });
    });
  };

export const createGame = (formValues) => async (dispatch, getState) => {
  // on creation, we must have a lat, lng and radius. So unlike edit, I
  // do not check first if formValues has a property lat to parse parseFloat
  validateCoords(formValues, true);

  // const userId = getState().auth.user.id;
  // const response = await gamesApi.post('/games',
  //     {...formValues, userId});

  // we do not pass user id, as it is included in the jwt token which is sent by default with each request
  //const response = await gamesApi.post('/api/design/games',formValues);
  return new Promise((resolve, reject) => {
    gamesApi
      .post('/api/design/games', formValues)
      .then((response) => {
        dispatch({ type: CREATE_GAME, payload: response.data });
        displaySuccess(`Game ${response.data.title} created`);
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error creating game`);
        reject();
      });
  });

  // programmatic navigation to get the user back to the root route
  // to BrowserRouter tou react router exei to history. Kai to history gientai passed sta components
  // Alla emeis den eimaste se component, alla se ena action
  // Tha mporouse to component, pera apo ta formvalues, na pernaei kai to history sto action.
  // Alla den einai wraio
  // Emeis, kanoume diko mas history object anti na afisoume to browserRouter na xeirizetai to history object
  // Opote kanoume import opote to xriazomaste. Anti BrowserRouter tha kanoume Plain <Router>
  //history.push('/');
};
