import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOMServer, { renderToStaticMarkup, renderToString } from 'react-dom/server';
import { Link, Router } from 'react-router-dom';
import history from '../../history';
import _ from 'lodash';
import { ICON_URL_DEFAULT } from './iconUrls';
import { Button, Image, Row } from 'react-bootstrap';

// import {connect} from 'react-redux';
// import {Link} from 'react-router-dom';
// import {fetchGameScenes} from '../../actions';
// import {fetchGame} from "../../actions";

const MarkerMap = (props) => {
  let mapStyles = {
    width: '100%',
    height: props.height || '600px',
    backgroundColor: 'white',
    border: '2 solid lightgray',
    borderRadius: 5,
  };

  if (props.mapStyles) {
    mapStyles = props.mapStyles;
  }
  const googleMap = useRef(null);
  const infowindow = useRef(null);
  const googleMapDivRef = useRef(null);

  //let gmapScriptIncluded = useRef(false);
  const markers = useRef([]); // san instance variable pou den allazei me kathe render, gia na krataw th lista twn existing markers gia na mporw na ta afairesw
  const { markersData, onMarkerContentClick } = props;

  const [skipRebound, setSkipRebound] = useState(false);
  const initMap = () => {
    if (googleMap.current === null) {
      googleMap.current = new window.google.maps.Map(googleMapDivRef.current, {
        zoom: 5,
        center: {
          lat: 10,
          lng: 20,
        },
      });
    }
    setUpMarkers();
    return 0;
  };

  const handleCreateMarker = (markerData) => {
    if (!markerData.lat || !markerData.lng) {
      // console.log('No coords for markerData with id ' + markerData.id);
      return;
    }
    //console.log('creating marker for data: ', { markerData });
    const markerOptions = {
      position: { lat: parseFloat(markerData.lat), lng: parseFloat(markerData.lng) },
      map: googleMap.current,
      draggable: false,
    };
    if (markerData.iconUrl && markerData.iconMarker) {
      markerOptions.icon = {
        url: markerData.iconUrl,
        scaledSize: new window.google.maps.Size(25, 25),
      };
    }
    if (markerData.onlyRadius) {
      var icon = {
        url: `${process.env.PUBLIC_URL}/resources/images/cross3.svg`,
        size: new window.google.maps.Size(100, 100), // orig size in svg file (width, height attribute)
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15 / 2, 15 / 2),
        scaledSize: new window.google.maps.Size(15, 15),
      };
      markerOptions.icon = icon;
    }
    const marker = new window.google.maps.Marker(markerOptions);
    marker.markerData = markerData;
    if (markerData.radius) {
      marker.radiusCircle = new window.google.maps.Circle({
        map: googleMap.current,
        radius: markerData.radius, // in metres
        strokeWeight: markerData.strokeWeight ?? 1,
        strokeOpacity: 0.6,
        fillColor: markerData.radiusColor ? markerData.radiusColor : '#0e49aa',
        fillOpacity: 0.4,
      });
      marker.radiusCircle.bindTo('center', marker, 'position');
    }
    markers.current.push(marker);
    marker.addListener('click', function () {
      infowindow.current.setContent(ReactDOMServer.renderToString(getInfowindowContent(marker.markerData)));
      infowindow.current.open(googleMap.current, marker);
    });
  };
  const setUpMarkers = () => {
    if (markers.current.length > 0) {
      // remove existing markers
      markers.current.forEach((num, index) => {
        markers.current[index].setMap(null);
        if (markers.current[index].radiusCircle) {
          markers.current[index].radiusCircle.setMap(null);
        }
      });
      markers.current = [];
    }

    if (infowindow.current === null) {
      infowindow.current = new window.google.maps.InfoWindow({
        content: 'initial text',
      });
    }

    if (!_.isEmpty(markersData)) {
      Object.values(markersData).forEach((markerData) => {
        handleCreateMarker(markerData);
      });
      const bounds = new window.google.maps.LatLngBounds();
      markers.current.forEach((num, index) => {
        bounds.extend(markers.current[index].getPosition());
      });

      // Don't zoom in too far on only one marker
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new window.google.maps.LatLng(bounds.getNorthEast().lat() + 0.005, bounds.getNorthEast().lng() + 0.005);
        const extendPoint2 = new window.google.maps.LatLng(bounds.getNorthEast().lat() - 0.005, bounds.getNorthEast().lng() - 0.005);
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }

      if (!skipRebound) {
        googleMap.current.fitBounds(bounds);
        setSkipRebound(true);
      }
    }
  };

  const getInfowindowContent = (markerData) => {
    return (
      <button variant='outline' id='marker-button' style={{ width: 200, margin: 5, fontSize: 20, alignItems: 'center', padding: 10 }}>
        {markerData.iconUrl && <Image roundedCircle src={markerData.iconUrl} alt='Player' width={40} height={40} />}
        {markerData.typeLabel && <span>{markerData.typeLabel}:</span>}
        <span> {markerData.name} </span>
      </button>
    );
  };

  const delayedInitMap = useCallback(() => {
    if (typeof window.google !== 'object' || typeof window.google.maps !== 'object') {
      setTimeout(delayedInitMap, 500);
    } else {
      initMap();
    }
  });
  useEffect(() => {
    setTimeout(delayedInitMap, 500);
  }, [delayedInitMap, markersData]);

  return <div className='marker-map' ref={googleMapDivRef} style={mapStyles} />;
};

export default MarkerMap;
