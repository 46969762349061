import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { connect, useSelector } from 'react-redux';

import renderError from './renderers/renderError';
import Image from 'react-bootstrap/Image';

import Button from 'react-bootstrap/Button';
import { getThumbUrl, getThumbUrlById } from '../utils/mediaUtils';
import MediaModal from '../media/MediaModal';
import { getUserRightsOnGame } from '../../utils/utils';
import { isEmpty } from 'lodash';

export default function InlineImageChoose({ input, meta, gameId, imageWidth }) {
  const [showMediaModal, setShowMediaModal] = useState(isEmpty(input.value));
  const className = `inline-media-field ${meta.error ? 'invalid' : ''}`;

  const game = useSelector((state) => state.games[gameId]);
  const user = useSelector((state) => state.auth.user);
  const userRights = getUserRightsOnGame(game, user);

  const handleShowMediaModal = () => setShowMediaModal(true);
  const handleCloseMediaModal = () => setShowMediaModal(false);

  const handleMediaChoosen = (newId) => {
    input.onChange(newId);
    setShowMediaModal(false);
  };
  return (
    <>
      <Form.Control {...input} className={className} type={'hidden'} />
      {userRights.includes('edit') && <Image onClick={handleShowMediaModal} src={getThumbUrlById(input.value, true, imageWidth)} className={className} />}
      {!userRights.includes('edit') && <Image src={getThumbUrlById(input.value, true, imageWidth)} className='inline-media-field' />}
      <MediaModal
        initialSelectedMedia={isEmpty(input.value) ? null : { id: input.value }}
        openMediaModal={showMediaModal}
        handleMediaChoosen={handleMediaChoosen}
        closeMediaModal={handleCloseMediaModal}
        gameId={gameId}></MediaModal>
      {meta.error && (
        <Form.Row>
          <Col lg={'auto'}>{renderError(meta)}</Col>
        </Form.Row>
      )}
    </>
  );
}
