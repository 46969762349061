//import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { editMilestone, createMilestone, deleteMilestone } from '../../actions/milestonesActions';

import MilestoneForm from '../forms/MilestoneForm';
import history from '../../history';
import LocationForm from '../forms/LocationForm';
import { bconfirm } from '../utils/ConfirmAlert';

const MilestoneEdit = ({ dispatch, milestone, game, onCancel, onSave, afterDelete }) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      return;
    }
    history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
  };
  const handleDelete = async (id) => {
    const result = await bconfirm('Are you really sure?', { cancelButtonStyle: 'secondary' });
    if (result) {
      dispatch(deleteMilestone(id))
        .then(() => {
          if (afterDelete) {
            afterDelete();
            return;
          } else {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
          }
        })
        .catch((e) => {
          /* do nothing. A toastr notification should have been displayed */
        });
    }
  };
  const onSubmit = (formValues) => {
    // console.log('MilestoneEdit onSubmit');
    if (milestone) {
      dispatch(editMilestone(milestone.id, formValues))
        .then((value) => {
          if (onSave) {
            onSave(value);
            return;
          } else {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
          }
        })
        .catch((e) => {
          /* do nothing. An error toastr should have been displayed */
        });
    } else {
      dispatch(createMilestone(game.id, formValues))
        .then((value) => {
          if (onSave) {
            onSave(value);
            return;
          } else {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
          }
        })
        .catch((e) => {
          /* do nothing. An error toastr should have been displayed */
        });
    }
  };
  const defaultInitialValues = {
    gameId: game.id,
  };

  return (
    <div className=''>
      <MilestoneForm enableReinitialize={true} handleCancel={handleCancel} handleDelete={handleDelete} initialValues={milestone ? milestone : defaultInitialValues} game={game} onSubmit={onSubmit} />
      {
        // initialValues={_.pick(game, 'title', 'description', 'lat', 'lng', 'radius')}
      }
    </div>
  );
};

export default connect(null, null)(MilestoneEdit);
