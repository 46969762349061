//import _ from 'lodash';
import React from 'react';
import SceneList from '../scenes/SceneList';
import MilestoneEdit from '../milestones/MilestoneEdit';
import MissionEdit from '../missions/MissionEdit';
import GameVersionEdit from '../gameVersions/GameVersionEdit';
import RuleEdit from '../rules/RuleEdit';
import ItemEdit from '../items/ItemEdit';
import InitialStateEdit from '../initialState/InitialStateEdit';

import CharacterEdit from '../characters/CharacterEdit';
import AttributeEdit from '../attributes/AttributeEdit';
import ArchetypeEdit from '../archetypes/ArchetypeEdit';
import OfferEdit from '../offers/OfferEdit';
import InteractionEdit from '../interactions/InteractionEdit';
import SceneShow from '../scenes/SceneShow';
import SceneEdit from '../scenes/SceneEdit';
import history from '../../history';
import ManageInitialStateItems from '../initialState/ManageInitialStateItems';

const MainArea = ({
  game,
  scenes,
  locations,
  interactions,
  rules,
  items,
  characters,
  archetypes,
  offers,
  attributes,
  milestones,
  missions,
  gameVersions,
  objecttype,
  objectaction,
  objectid,
  modifier1,
  returnTo,
}) => {
  const liveSpec = gameVersions.find((v) => v.isLiveSpec);
  if (!objecttype || !objectaction || !objectid) {
    if (game) {
      return <SceneList game={game} scenes={scenes} locations={locations} displayAs={'map'} />;
    }
    return <>Loading...</>;
  }

  switch (objecttype) {
    case 'scenes':
      const scene = Object.values(scenes).find((element) => element.id === objectid);
      if (!scene && objectid !== '0') {
        return <>Loading scene</>;
      }
      let onsaveHandler = null;
      if (returnTo === 'objectShow' && scene) {
        onsaveHandler = () => {
          // http://localhost:3000/games/60c1ce48965da000c2a9eb43/scenes/show/60c1eed9264f6602f2fcd0a4
          history.push(`/games/${game.id}/scenes/show/${scene.id}`);
        };
      }
      switch (objectaction) {
        case 'edit':
          return <SceneEdit game={game} scene={scene} onSave={onsaveHandler} />;
        case 'show':
          return <SceneShow game={game} locations={locations} rules={rules} scene={scene} />;
        case 'create':
          return <SceneEdit game={game} />;
        default:
          return <>SCENES OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'rules':
      const rule = Object.values(rules).find((element) => element.id === objectid);
      if (rule && rule.idx === 0) {
        const sceneRules = Object.values(rules).filter((element) => element.gameId === rule.gameId && element?.sceneId === rule.sceneId);
        let ruleIdx = Object.values(sceneRules).findIndex((element) => element.id === rule.id);
        if (ruleIdx < 0) ruleIdx = 0;
        rule.idx = ruleIdx + 1;
      }
      const scene1 = scenes.find((element) => element.id === (rule ? rule.sceneId : modifier1)); // for create, the scene should be in modifier1
      switch (objectaction) {
        case 'edit':
          return <RuleEdit game={game} scene={scene1} rule={rule} />;
        case 'create':
          return <RuleEdit scene={scene1} game={game} />;
        default:
          return <>RULE OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'initialState':
      //console.log({objectaction, objecttype, objectid, modifier1})

      switch (objectaction) {
        case 'manage-offers':
          return <ManageInitialStateItems spec={liveSpec} type='offers' game={game} />;
        case 'manage-objects':
          return <ManageInitialStateItems spec={liveSpec} type='objects' game={game} />;
        case 'create':
          return <InitialStateEdit type={modifier1} game={game} />;
        case 'edit':
          return <InitialStateEdit type={modifier1} objectId={objectid} game={game} />;
        default:
          return <>RULE OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'items':
      const item = Object.values(items).find((element) => element.id === objectid);
      if (!item && objectid !== '0') {
        return <>Loading item</>;
      }
      switch (objectaction) {
        case 'edit':
          return <ItemEdit game={game} item={item} />;
        // case 'show':
        //     return <SceneShow game={game} scene={item} />;
        case 'create':
          return <ItemEdit game={game} />;
        default:
          return <>ITEM OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'characters':
      const character = Object.values(characters).find((element) => element.id === objectid);
      if (!character && objectid !== '0') {
        return <>Loading character</>;
      }
      switch (objectaction) {
        case 'edit':
          return <CharacterEdit game={game} character={character} />;
        // case 'show':
        //     return <SceneShow game={game} scene={character} />;
        case 'create':
          return <CharacterEdit game={game} />;
        default:
          return <>CHARACTER OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'archetypes':
      const archetype = Object.values(archetypes).find((element) => element.id === objectid);
      if (!archetype && objectid !== '0') {
        return <>Loading archetyp</>;
      }
      switch (objectaction) {
        case 'edit':
          return <ArchetypeEdit game={game} archetype={archetype} />;
        // case 'show':
        //     return <SceneShow game={game} scene={object} />;
        case 'create':
          return <ArchetypeEdit game={game} />;
        default:
          return <>ARCHETYPE OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'offers':
      const offer = Object.values(offers).find((element) => element.id === objectid);
      if (!offer && objectid !== '0') {
        return <>Loading offer</>;
      }
      switch (objectaction) {
        case 'edit':
          return <OfferEdit game={game} offer={offer} />;
        // case 'show':
        //     return <SceneShow game={game} scene={object} />;
        case 'create':
          return <OfferEdit game={game} />;
        default:
          return <>ARCHETYPE OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'attributes':
      const attribute = Object.values(attributes).find((element) => element.id === objectid);
      if (!attribute && objectid !== '0') {
        return <>Loading attribute</>;
      }
      switch (objectaction) {
        case 'edit':
          return <AttributeEdit game={game} attribute={attribute} />;
        // case 'show':
        //     return <SceneShow game={game} scene={attribute} />;
        case 'create':
          return <AttributeEdit game={game} />;
        default:
          return <>ATTRIBUTE OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'milestones':
      const milestone = Object.values(milestones).find((element) => element.id === objectid);
      if (!milestone && objectid !== '0') {
        return <>Loading milestone</>;
      }
      switch (objectaction) {
        case 'edit':
          return <MilestoneEdit game={game} milestone={milestone} />;
        case 'create':
          return <MilestoneEdit game={game} />;
        default:
          return <>MILESTONE OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'missions':
      const mission = Object.values(missions).find((element) => element.id === objectid);
      if (!mission && objectid !== '0') {
        return <>Loading mission</>;
      }
      switch (objectaction) {
        case 'edit':
          return <MissionEdit game={game} mission={mission} />;
        case 'create':
          return <MissionEdit game={game} />;
        default:
          return <>MISSION OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'gameVersions':
      const gameVersion = Object.values(gameVersions).find((element) => element.id === objectid);
      if (!gameVersion && objectid !== '0') {
        return <>Loading gameVersion</>;
      }
      switch (objectaction) {
        case 'edit':
          return <GameVersionEdit game={game} gameVersion={gameVersion} />;
        case 'create':
          return <GameVersionEdit game={game} />;
        default:
          return <>GAMEVERSION OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }
    case 'interactions':
      const interaction = Object.values(interactions).find((element) => element.id === objectid);
      if (!interaction && objectid !== '0') {
        return <>Loading interaction</>;
      }
      let interactionScene;
      switch (objectaction) {
        case 'edit':
          // interactionScene = Object.values(scenes).find(element => element.id === interaction.sceneId);
          // if (isEmpty(interactionScene)) {
          //     return <div>Trying to create an interaction without a scene</div>;
          // }
          return <InteractionEdit game={game} interaction={interaction} />;
        case 'create':
          // for creation, i need the scene on which it will be created
          // it should be available in modifier1
          // interactionScene = Object.values(scenes).find(element => element.id === modifier1);
          // if (isEmpty(interactionScene)) {
          //     return <div>Trying to create an interaction without a scene</div>;
          // }
          // if (interactionScene.gameId !== game.id) {
          //     return <div>Trying to create an interaction with a scene from different game</div>;
          // }
          return <InteractionEdit game={game} newInteractionType={modifier1} />;
        default:
          return <>INTERACTION OBJECT TYPE BUT INVALID OBJECTACTION</>;
      }

    default:
      return <>No URL match for Main Area component</>;
  }
};

export default MainArea;
