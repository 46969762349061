import React from 'react';

import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { ATTRIBUTE_FORM_NAME as FORM_NAME } from './formTypes';
import renderInput from './renderers/renderInput';
//import renderBooleanRadio from "./renderers/renderBooleanRadio";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import FormButtons from './FormButtons';
import { asyncValidateForm, g4mFormTotalFormValidate, g4mFormValidate } from '../utils/validationUtils';
//import renderSelect from "./renderers/renderSelect";
//import {ATTACH_TO_OBJECT,ATTACH_TO_ACTOR} from "../attributes/attributeConstants";
import renderCheckbox from './renderers/renderCheckbox';
import ImageChoose from './ImageChoose';
import RichTextEditorWYSIWYG from './renderers/RichTextEditorWYSIWYG';
import { useTranslation } from 'react-i18next';

const selector = formValueSelector(FORM_NAME);

const AttributeForm = ({ error, handleSubmit, onSubmit, dispatch, initialValues, handleCancel, logoId, hidden, game, handleDelete }) => {
  const myOnSubmit = (formValues) => {
    onSubmit(formValues);
  };
  const { t, i18n } = useTranslation();

  return (
    <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
      <Field name='name' label={t('NAME_FIELD_LABEL')} tooltip={t('NAME_FIELD_TOOLTIP')} asCol={false} component={renderInput} />
      <Field name='title' label='Τitle' asCol={false} component={renderInput} />
      <Form.Row>
        <Col lg={10} md={9} sm={8}>
          <Field label='Description' name='description' component={RichTextEditorWYSIWYG} />
        </Col>
        <Col lg={2} md={3} sm={4}>
          <Field
            name='logoId'
            label='Media'
            logoIdToShow={logoId}
            handleRemoveMedia={() => dispatch(change(FORM_NAME, 'logoId', null))}
            gameId={initialValues && initialValues.gameId}
            imageWidth={300}
            required={true}
            handleSetMediaId={(newId) => dispatch(change(FORM_NAME, 'logoId', newId))}
            component={ImageChoose}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col lg={3}>
          <Field
            placementFix
            component={renderCheckbox}
            name='hidden'
            type='checkbox'
            label='Hidden'
            onChange={(e) => {
              if (e.target.checked) {
                dispatch(change(FORM_NAME, 'redeemable', null));
                dispatch(change(FORM_NAME, 'tradable', null));
                dispatch(change(FORM_NAME, 'notifyOnChange', null));
              }
            }}
          />
        </Col>
        {!hidden && (
          <Col>
            <Field component={renderCheckbox} name='notifyOnChange' type='checkbox' placementFix label='Notify on change' />
          </Col>
        )}
      </Form.Row>
      {!hidden && game.market && (
        <Form.Row>
          <Col>
            <Field component={renderCheckbox} name='tradable' type='checkbox' placementFix label='Tradeable' />
          </Col>

          <Col>
            <Field component={renderCheckbox} name='redeemable' type='checkbox' placementFix label='Redeemable' />
          </Col>

          <Col>
            <Field name='price' label={`Price (${game.currencyName})`} asCol={false} type='number' step='1' component={renderInput} />
          </Col>
        </Form.Row>
      )}

      <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id ? handleDelete : null} />
    </Form>
  );
};

const validate = (formValues) => {
  const errors = {};
  g4mFormValidate(errors, formValues, ['title']); // name is validated asynchronously
  g4mFormTotalFormValidate(errors);

  return errors;
};

// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
//  })(GameForm);

//
// export default reduxForm({
//     form: FORM_NAME,
//     validate:validate,
// })(AttributeForm);

export default connect((state, ownProps) => {
  return {
    hidden: selector(state, 'hidden'),
    logoId: selector(state, 'logoId'),
  };
})(
  reduxForm({
    form: FORM_NAME,
    validate: validate,
    asyncValidate: asyncValidateForm,
    asyncBlurFields: ['name'],
  })(AttributeForm)
);
