import gamesApi from '../apis/gamesApi';
import history from '../history';
import { FETCH_GAME_RULES, FETCH_RULE, EDIT_RULE, CREATE_RULE, DELETE_RULE } from './types';
import { displayError, displaySuccess } from '../components/utils/notifications';

export const createRule = (gameId, formValues) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .post(`/api/design/rules?gameId=${gameId}`, { ...formValues, gameId })
      .then((response) => {
        dispatch({ type: CREATE_RULE, payload: response.data });
        displaySuccess(`Rule ${response.data.name} created`);
        resolve();
        //history.push(`/games/${gameId}`);
      })
      .catch((err) => {
        displayError(err, `Error creating rule`);
        reject();
      });
  });
};

export const fetchGameRules = (gameId) => async (dispatch) => {
  if (gameId) {
    return new Promise((resolve, reject) => {
      gamesApi
        .get(`/api/design/rules?gameId=${gameId}`)
        .then((response) => {
          dispatch({ type: FETCH_GAME_RULES, payload: response.data });
          resolve();
        })
        .catch((err) => {
          displayError(err, `Error retrieving game rules`);
          reject();
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      dispatch({ type: FETCH_GAME_RULES, payload: [] });
      displayError(null, `No gameId id in fetchGameRules`);
      reject();
    });
  }
};

export const fetchRule = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .get(`/api/design/rules/${id}`)
      .then((response) => {
        dispatch({ type: FETCH_RULE, payload: response.data });
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error retrieving rule data for id ${id}`);
        reject();
      });
  });
};
export const editRule = (id, formValues) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .patch(`/api/design/rules/${id}`, formValues)
      .then((response) => {
        dispatch({ type: EDIT_RULE, payload: response.data });
        displaySuccess(`Rule ${response.data.name} saved`);
        resolve();
        // history.push(`/games/${gameId}`);
      })
      .catch((err) => {
        displayError(err, `Error saving rule ${id}`);
        reject();
      });
  });
};
export const deleteRule = (ruleId, gameId, redirectUrl) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .delete(`/api/design/rules/${ruleId}?gameId=${gameId}`)
      .then((response) => {
        dispatch({ type: DELETE_RULE, payload: ruleId });
        displaySuccess(`Rule deleted`, `Rule ${ruleId} was deleted`);
        resolve();
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      })
      .catch((err) => {
        displayError(err, `Error deleting rule ${ruleId}`);
        reject();
      });
  });
};
