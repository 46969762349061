import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { registerUser } from '../../actions/authActions';
import SocialLoginButtons from './SocialLoginButtons';
import MainContent from '../layouts/MainContent';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      password: '',
      password2: '',
      errors: {},
    };
  }
  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/');
    }
  }
  /*
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }*/

  /* Antikathista to componentWillReceiveProps https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html#updating-state-based-on-props */
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
    };
    // console.log('GOT NEW USER');
    //console.log(newUser);
    this.props.registerUser(newUser, this.props.history);
  };
  render() {
    const { errors } = this.state;
    return (
      <>
        <MainContent isAuthView header='' hasSideBar={false}>
          <div className='row justify-content-md-center logo-container'>
            <div className='col-md-3 mb-3 text-center'>
              <img width='400' src={`${process.env.PUBLIC_URL}/resources/images/rollick_icon_transparrent.svg`} className='' alt='Rollick logo' />
            </div>
          </div>

          <div className='row justify-content-center'>
            <div className='card col-sm-8 col-md-6 col-lg-4'>
              <article className='card-body'>
                <LinkContainer to={`/login`}>
                  <Button variant='outline-primary' className='float-right'>
                    Sign in
                  </Button>
                </LinkContainer>

                <h4 className='card-title mb-4 mt-1'>Sign up</h4>

                <SocialLoginButtons />
                <hr />
                <p className={'red-text'}>{errors.generalError}</p>
                <Form noValidate onSubmit={this.onSubmit}>
                  <Form.Group controlId='name'>
                    <Form.Label>Full name</Form.Label>
                    <Form.Control onChange={this.onChange} value={this.state.name} error={errors.name} type='text' className={errors.name ? 'invalid' : ''} placeholder='Enter your full name' />
                    <Form.Text className='text-muted'>
                      <span className={'red-text'}>{errors.name}</span>
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId='email'>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control onChange={this.onChange} value={this.state.email} error={errors.email} type='email' className={errors.email ? 'invalid' : ''} placeholder='Enter email' />
                    <Form.Text className='text-muted'>
                      <span className={'red-text'}>{errors.email}</span>
                    </Form.Text>
                    <Form.Text className='text-muted'>We'll never share your email with anyone else.</Form.Text>
                  </Form.Group>
                  <Form.Group controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control onChange={this.onChange} value={this.state.password} error={errors.password} type='password' className={errors.password ? 'invalid' : ''} placeholder='*****' />
                    <Form.Text className='text-muted'>At least 8 characters.</Form.Text>
                    <Form.Text className='text-muted'>
                      <span className={'red-text'}>{errors.password}</span>
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId='password2'>
                    <Form.Label>Confirm password</Form.Label>
                    <Form.Control onChange={this.onChange} value={this.state.password2} error={errors.password2} type='password' className={errors.password2 ? 'invalid' : ''} placeholder='*****' />
                    <Form.Text className='text-muted'>
                      <span className={'red-text'}>{errors.password2}</span>
                    </Form.Text>
                  </Form.Group>

                  <div className='row justify-content-end'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <Form.Text className='text-muted'>
                          <span className={'red-text'}>{errors.generalerror}</span>
                        </Form.Text>
                        <button type='submit' className='btn btn-primary btn-block'>
                          {' '}
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </article>
            </div>
          </div>
        </MainContent>
      </>
    );
  }
}
//export default Register;
Register.defaultProps = {
  errors: {},
};
Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { registerUser })(withRouter(Register));
