import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import Modal from 'react-bootstrap/Modal';

const modalRoot = document.getElementById('modal-root');
//const modalRootMilestone = document.getElementById("modal-root-milestone");
const containerElements = {};
// inserted into modal-root using portal so that if the modal is inserted within a form,
// it's submit button does not also submit the form
// see https://dev.to/spukas/react-portals-flexible-modal-implementation-5310
const ModalEdit = ({ openEditModal, closeEditModal, children, heading, portal, width }) => {
  if (!portal) {
    // should never happen
    alert('ModalEdit component, portal is empty');
  } else {
    if (!containerElements[portal]) {
      const el = document.createElement('div');
      el.setAttribute('data-portal', portal);
      containerElements[portal] = el;
    }
  }

  const onEntered = (a, b) => {
    // var zIndex = 1040 + (10 * $('.modal:visible').length);
    // $(this).css('z-index', zIndex);
    // setTimeout(function() {
    //     $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    // }, 0);
    //// console.log('onEntered MODAL',a,document.querySelectorAll('.modal:not([style="display:none"]').length);
    // let zIndex = 1040 + (10 * document.querySelectorAll('.modal:not([style="display:none"]').length);
    //// console.log('+++++++++++++++++', a.style.zIndex);
    // //a.style.zIndex =  zIndex;
    // //document.querySelector('.modal-90w')
    // setTimeout(function() {
    //     //$('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
    // }, 0);
  };

  // const getModalRoot = () => {
  //     if (portal === 'milestone') {
  //        // console.log("RETURNING MILESTONE MODALROOT", );
  //         return modalRootMilestone;
  //     }
  //    // console.log("RETURNING COMMON MODALROOT");
  //
  //     return modalRoot;
  // }

  useEffect(() => {
    // append to root when the children of Modal are mounted
    //const theModalRoot = getModalRoot();
    //// console.log('modal root to use:', theModalRoot);
    modalRoot.appendChild(containerElements[portal]);
    // do a cleanup
    return () => {
      if (modalRoot.contains(containerElements[portal])) {
        modalRoot.removeChild(containerElements[portal]);
      }
    };
  }, [portal]);

  //console.log('adding modal to element ',containerElements[portal]);

  if (!openEditModal) {
    return <></>;
  }
  return createPortal(
    <Modal dialogClassName={`modal-${width ?? '90'}w`} data-portal={portal} size='lg' onEntered={onEntered} show={openEditModal} onHide={closeEditModal}>
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>,
    // target container
    containerElements[portal]
  );
};

export default ModalEdit;
