import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, change, getFormValues, reduxForm } from 'redux-form';
import { INITIAL_STATE_ITEM_FORM_NAME as FORM_NAME } from './formTypes';
import renderInput from './renderers/renderInput';

import { isPointWithinRadius } from 'geolib';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import GoogleMapObjectForm from '../gmap/GoogleMapObjectForm';
import { getThumbUrlById } from '../utils/mediaUtils';
import FormButtons from './FormButtons';
import ImageChoose from './ImageChoose';
import renderSelect from './renderers/renderSelect';
import RichTextEditorWYSIWYG from './renderers/RichTextEditorWYSIWYG';
import renderCheckbox from './renderers/renderCheckbox';
import renderSwitch from './renderers/renderSwitch';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ModalEdit from '../utils/ModalEdit';
import QrCodeForm from '../qr-codes/QrCodeForm';

const ObjectForm = ({ error, scenes, gameSpec, formValues, dispatch, handleSubmit, onSubmit, handleCancel, handleDelete, initialValues, game }) => {
  const spec = gameSpec.spec && gameSpec.spec.resources.archetypes.find((i) => i.id === formValues?.archetypeId);

  const [openEditQrCodeModal, setOpenEditQrCodeModal] = useState(false);
  const closeEditQrCodeModal = () => {
    setOpenEditQrCodeModal(false);
  };
  useEffect(() => {
    // console.log({ formValues });
  }, [formValues]);

  const renderInputField = (payloadId) => {
    const fieldSpec = spec.payloadSpec[payloadId];
    if (fieldSpec.type === 'string' || fieldSpec.type === 'email') {
      return <Field key={payloadId} name={`payload.${payloadId}`} label={fieldSpec.label} type='text' component={renderInput} />;
    } else if (fieldSpec.type === 'number') {
      return <Field key={payloadId} name={`payload.${payloadId}`} label={fieldSpec.label} type='number' component={renderInput} />;
    } else if (fieldSpec.type === 'text') {
      return <Field key={payloadId} name={`payload.${payloadId}`} label={fieldSpec.label} type='textarea' component={RichTextEditorWYSIWYG} />;
    } else if (fieldSpec.type === 'image') {
      return (
        <React.Fragment key={payloadId}>
          <Field
            name={`payload.${payloadId}.id`}
            label={fieldSpec.label}
            logoIdToShow={formValues.payload && formValues.payload[payloadId] && formValues.payload[payloadId].id}
            handleRemoveMedia={() => {
              dispatch(change(FORM_NAME, `payload.${payloadId}`, null));
            }}
            gameId={game.id}
            imageWidth={800}
            handleSetMediaId={(newId) => {
              dispatch(change(FORM_NAME, `payload.${payloadId}.id`, newId));
              dispatch(change(FORM_NAME, `payload.${payloadId}.type`, 'image'));
              dispatch(change(FORM_NAME, `payload.${payloadId}.uri`, getThumbUrlById(newId, false, 800, 0)));
            }}
            component={ImageChoose}
          />
          <Field name={`payload.${payloadId}.type`} type='hidden' value='image' component='input' />
        </React.Fragment>
      );
    } else {
      return `Type ${fieldSpec.type} not yet supported`;
    }
  };
  const myOnSubmit = (formValues) => {
    onSubmit(formValues);
  };

  const currentArchetypeSpec = () => {
    if (!spec) {
      return '';
    }
    const children = [];
    if (spec.moderated) {
      children.push(
        <Row className='py-2'>
          <Col>
            <Field name='approved' type='checkbox' label='Approved for publication' noFormGroup={true} component={renderCheckbox} />
          </Col>
        </Row>
      );
    }
    const payloadFields = Object.keys(spec.payloadSpec || {}).map((payloadId) => renderInputField(payloadId));
    return children.concat(payloadFields);
  };

  const getScenesItemList = () => {
    return scenes.map((scene) => {
      return (
        <option key={scene.id} value={scene.id}>
          {scene.name}
        </option>
      );
    });
  };

  const getAvailableArchetypesItemList = () => {
    if (gameSpec.spec.resources.archetypes.length === 0) {
      return null;
    }
    return gameSpec.spec.resources.archetypes.map((item) => {
      //const itemId = item.id;
      return (
        <option key={item.id} value={item.id}>
          {item.title}
        </option>
      );
    });
  };

  const onMarkerDrag = (lat, lng) => {
    const scene = scenes.find((scene) => isPointWithinRadius({ latitude: lat, longitude: lng }, scene.place.center, scene.place.radius));
    dispatch(change(FORM_NAME, 'sceneId', scene?.id ?? ''));
    dispatch(change(FORM_NAME, 'location.latitude', lat));
    dispatch(change(FORM_NAME, 'location.longitude', lng));
  };
  if (!gameSpec || !gameSpec.spec || !gameSpec.spec.resources || !gameSpec.spec.resources.archetypes) {
    return <>Loading....</>;
  }
  //
  // payloadSpec	{propertyName*:PropertySpec}
  // ok location	GpsCoords
  // ok archetypeId	string
  return (
    <Form noValidate onSubmit={handleSubmit(myOnSubmit)}>
      <Row>
        <Col>
          <Field name={'name'} label={'System Name'} type='text' component={renderInput} />
          <Field
            name='sceneId'
            component={renderSelect}
            label='Scene'
            noFormGroup={true}
            onChange={(e) => {
              const scene = scenes.find((s) => s.id === e.target.value);
              if (!scene) {
                return;
              }
              dispatch(change(FORM_NAME, `location`, { latitude: scene.place.center.latitude, longitude: scene.place.center.longitude }));
            }}>
            <option key='select-item' value={''}>
              -- Select one --
            </option>
            {getScenesItemList()}
          </Field>
          <Field
            name='archetypeId'
            component={renderSelect}
            label='Object Type'
            hidden={true}
            noFormGroup={true}
            onChange={() => {
              dispatch(change(FORM_NAME, `payload`, {}));
              const scene = scenes.find((s) => s.id === formValues.sceneId);
              dispatch(change(FORM_NAME, `location`, scene ? { latitude: scene.lat, longitude: scene.lng } : {}));
            }}>
            <option key='select-item'>-- Select one --</option>
            {getAvailableArchetypesItemList()}
          </Field>
          <div className='d-flex pt-4 full-width'>
            <OverlayTrigger
              placement={'top'}
              overlay={
                <Tooltip id={`qrTooltip}`}>
                  A QR code that when scanned, simulates a contact of the player with the current object. The object behaviour defined under "When Contacted" will be triggered.
                </Tooltip>
              }>
              <Button variant='outline-secondary' block onClick={() => setOpenEditQrCodeModal(true)}>
                <i className='fas fa-qrcode'></i> Direct Object Contact QR code
              </Button>
            </OverlayTrigger>
            <ModalEdit width={60} openEditModal={openEditQrCodeModal} closeEditModal={closeEditQrCodeModal} heading={'Get QR Code'} portal={'milestone'}>
              <QrCodeForm
                eventType='de'
                eventName={`Contact_${formValues?.archetypeId}`}
                closeEditQrCodeModal={closeEditQrCodeModal}
                gameId={game.id}
                sceneId={formValues?.sceneId}
                objectId={formValues?.id}
              />
            </ModalEdit>
          </div>
          {currentArchetypeSpec()}
        </Col>
        <Col>
          <div className='row mt-3 mt-md-0'>
            <div className='col-6'>
              <Field name='location.latitude' normalize={(value) => (value ? parseFloat(value) : 0)} label='Latitude' type='number' step='0.001' component={renderInput} />
            </div>
            <div className='col-6'>
              <Field name='location.longitude' normalize={(value) => (value ? parseFloat(value) : 0)} label='Longitude' type='number' step='0.001' component={renderInput} />
            </div>
          </div>

          <GoogleMapObjectForm
            formName={FORM_NAME}
            noRadius={true}
            radius={10}
            lat={formValues?.location?.latitude ?? gameSpec?.pg.place?.lat}
            lng={formValues?.location?.longitude ?? gameSpec?.pg.place?.lng}
            onMarkerDrag={onMarkerDrag}
            style={{ height: '700px' }}
            inScenes={scenes}
          />
        </Col>
      </Row>

      <FormButtons validationGeneralError={error} handleCancel={handleCancel} objectId={initialValues.id} handleDelete={initialValues.id ? handleDelete : null} />
    </Form>
  );
};

const validate = (formValues) => {
  const errors = {};

  if (!formValues.name || formValues.name === '') {
    errors.name = 'System name is required';
  }

  if (!formValues.sceneId || formValues.sceneId === '') {
    errors.sceneId = 'Select a scene';
  }

  if (!formValues.archetypeId || formValues.archetypeId === '') {
    errors.archetypeId = 'Select an object type';
  }
  return errors;
};

export default connect((state, ownProps) => {
  return {
    formValues: getFormValues(FORM_NAME)(state),
  };
})(
  reduxForm({
    form: FORM_NAME,
    validate: validate,
  })(ObjectForm)
);
