//import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { editLocation, createLocation, deleteLocation } from '../../actions/locationsActions';

import LocationForm from '../forms/LocationForm';
import history from '../../history';
import ItemForm from '../forms/ItemForm';
import { bconfirm } from '../utils/ConfirmAlert';

const LocationEdit = ({ dispatch, location, game, scene, onCancel, onSave, afterDelete }) => {
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
      return;
    }
    history.push(`/games/${game.id}/scenes/show/${scene.id}`); // if no specific handler is used, redirect to scene design page
  };
  const handleDelete = async (id) => {
    const result = await bconfirm('Are you really sure?', { cancelButtonStyle: 'secondary' });
    if (result) {
      dispatch(deleteLocation(id))
        .then(() => {
          if (afterDelete) {
            afterDelete();
            return;
          } else {
            history.push(`/games/${game.id}`); // if no specific handler is used, redirect to game design page
          }
        })
        .catch((e) => {
          /* do nothing. A toastr notification should have been displayed */
        });
    }
  };
  const onSubmit = (formValues) => {
    // console.log('LocationEdit onSubmit');
    if (location) {
      dispatch(editLocation(location.id, formValues))
        .then((value) => {
          if (onSave) {
            onSave(value);
            return;
          } else {
            history.push(`/games/${game.id}/scenes/show/${scene.id}`); // if no specific handler is used, redirect to scene design page
          }
        })
        .catch((e) => {
          /* do nothing. An error toastr should have been displayed */
        });
    } else {
      dispatch(createLocation(game.id, formValues))
        .then((value) => {
          if (onSave) {
            onSave(value);
            return;
          } else {
            history.push(`/games/${game.id}/scenes/show/${scene.id}`); // if no specific handler is used, redirect to scene design page
          }
        })
        .catch((e) => {
          /* do nothing. An error toastr should have been displayed */
        });
    }
  };
  if (!game || !scene) {
    return <>Waiting for game or scene data...</>;
  }
  const defaultInitialValues = {
    lat: scene.lat,
    lng: scene.lng,
    sceneId: scene.id,
    gameId: game.id,
    radius: 50,
  };

  return (
    <div className=''>
      <LocationForm
        enableReinitialize={true}
        handleCancel={handleCancel}
        handleDelete={handleDelete}
        initialValues={location ? location : defaultInitialValues}
        onSubmit={onSubmit}
        scene={scene}
        game={game}
      />
      {
        // initialValues={_.pick(game, 'title', 'description', 'lat', 'lng', 'radius')}
      }
    </div>
  );
};

export default connect(null, null)(LocationEdit);
