import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MainContent from '../layouts/MainContent';
import { withRouter } from 'react-router-dom';
import './spinner.css';
import { useState } from 'react';

import authapi from '../../apis/authapi';
import Form from 'react-bootstrap/Form';

const Forgot = ({ location }) => {
  const [sendLinkResult, setSendLinkResult] = useState(null);
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');
  const SENDLINK_SUCCESS = 'SENDLINK_SUCCESS';
  const SENDLINK_FAILED = 'SENDLINK_FAILED';

  const SENDLINK_GENERALERROR = 'SENDLINK_GENERALERROR';

  const sentComponent = (
    <>
      <p>Στάλθηκε μήνυμα στη διεύθυνση που δώσατε για την επανάθεση του κωδικού πρόσβασης.</p>
      <p>A message has been sent to your email address for resetting your password.</p>
    </>
  );
  const failedComponent = (
    <>
      <p>Γενικό σφάλμα (2).</p>
      <p>General error (2).</p>
    </>
  );

  const generalErrorComponent = (
    <>
      <p>Γενικό σφάλμα (1).</p>
      <p>General error (1).</p>
    </>
  );
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    authapi
      .post('/api/users/forgot', { email })
      .then((response) => {
        if (response && response.data && response.data.success === true) {
          setSendLinkResult(SENDLINK_SUCCESS);
        } else {
          setSendLinkResult(SENDLINK_FAILED);
        }
      })
      .catch((err) => {
        // console.log('forgot general error 2', err, err.response);
        if (err && err.response && err.response.data && err.response.data.success === false) {
          setErrors(err.response.data);
        }
        //setSendLinkResult(SENDLINK_GENERALERROR);
      });
  };

  const formComponent = (
    <>
      <div className='row justify-content-md-center logo-container'>
        <div className='mb-3 text-center'>
          <img width='400' src={`${process.env.PUBLIC_URL}/resources/images/rollick_icon_transparrent.svg`} className='' alt='Rollick logo' />
        </div>
      </div>
      <p className='text-secondary'>Συμπληρώστε τη διεύθυνση ηλεκτρονικού ταχυδρομείου του λογαριασμού σας για να σας σταλεί μήνυμα επανάθεσης του μυστικού κωδικού.</p>
      <p className='text-secondary'>Enter your email address to get a message for resetting your password.</p>
      <p className={'red-text'}>{errors.generalError}</p>
      <Form noValidate onSubmit={onSubmit}>
        <Form.Group controlId='email'>
          <Form.Label>Διεύθυνση Email / Email address</Form.Label>
          <Form.Control onChange={onEmailChange} value={email} error={errors.email} type='email' className={errors.email || errors.emailnotfound ? 'invalid' : ''} placeholder='Enter email' />
          <Form.Text className='text-muted'>
            <span className={'red-text'}>
              {errors.email}
              {errors.emailnotfound}
            </span>
          </Form.Text>
        </Form.Group>

        <div className='row'>
          <div className='col-md-12'>
            <div className='form-group'>
              <button type='submit' className='btn btn-primary btn-block'>
                {' '}
                Υποβολή / Submit
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );

  // <LinkContainer to={`/login`}>
  //     <a href="/login"> Sign in</a>
  // </LinkContainer>

  let msgComponent = null;

  if (sendLinkResult === null) {
    msgComponent = formComponent;
  } else if (sendLinkResult === SENDLINK_SUCCESS) {
    msgComponent = sentComponent;
  } else if (sendLinkResult === SENDLINK_FAILED) {
    msgComponent = failedComponent;
  } else if (sendLinkResult === SENDLINK_GENERALERROR) {
    msgComponent = generalErrorComponent;
  }
  //http://localhost:3000/confirmemail?mac=24ff69aaef4ff8cc5cee0f11b3f710806bebb0ac4d0b634a0862247482858765&msg=sk%2B55%40karasavvidis.gr_00_1643971404555
  //  validationResult: {validationResult}
  return (
    <>
      <MainContent isAuthView header='' hasSideBar={false}>
        <div className='row justify-content-md-center'>
          <div className='card col-md-3'>
            <article className='card-body'>{msgComponent}</article>
          </div>
        </div>
      </MainContent>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    //isAuthenticated: state.auth.isAuthenticated
  };
};

export default withRouter(connect(mapStateToProps, null)(Forgot));
