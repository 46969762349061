//import _ from 'lodash';
import React from 'react';

import { Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import { shortenText } from '../../utils/utils';
import MarkerMap from '../gmap/MarkerMap';
import { ICON_URL_LOCATION } from '../gmap/iconUrls';
import LocationList from '../locations/LocationList';
import RuleList from '../rules/RuleList';

const SceneShow = ({ scene, game, locations, rules }) => {
  if (!scene || !game) {
    return <>Loading...</>;
  }

  const markersData = [];
  const locationObject = scene;
  locationObject.onlyRadius = true;
  locationObject.typeLabel = 'Scene center';
  locationObject.radiusColor = '#d7fffc';
  markersData.push(locationObject); // add scene

  // add all locations of scene
  locations.map((markerData) => {
    if (markerData.sceneId === scene.id) {
      // console.log(markerData);
      const locationObject = markerData;
      locationObject.onlyRadius = true;
      locationObject.iconUrl = ICON_URL_LOCATION;
      locationObject.typeLabel = 'Location';
      locationObject.radiusColor = '#520040';

      markersData.push(locationObject);
    }
  });

  return (
    <div className=''>
      <h3>
        {scene.name}
        <LinkContainer to={`/games/${game.id}/scenes/edit/${scene.id}?returnTo=objectShow`}>
          <Button className={'ml-1 float-right'}>
            <i className='fas fa-cog'></i> Settings
          </Button>
        </LinkContainer>
      </h3>
      <p>{shortenText(scene.description, 400, '')}</p>
      <Tabs defaultActiveKey='script' id='scenetabs'>
        <Tab eventKey='script' title='Scene Script'>
          <RuleList game={game} scene={scene} rules={rules} displayAs={'list'} />
        </Tab>
        {game.pg?.type === 'map' && (
          <Tab eventKey='locations' title='Scene Places'>
            <LocationList game={game} scene={scene} locations={locations} displayAs={'list'} />
          </Tab>
        )}
        {game.pg?.type === 'map' && (
          <Tab className='mt-2' eventKey='map' title='Scene Map'>
            <MarkerMap markersData={markersData}></MarkerMap>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default SceneShow;
