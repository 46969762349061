import React from 'react';
import { Button, Card, Col, Dropdown, Image, Row } from 'react-bootstrap';
import { getUserRightsOnGame, shortenText } from '../../utils/utils';
import { format } from 'date-fns';
import { el } from 'date-fns/locale';
import { LinkContainer } from 'react-router-bootstrap';
import { getThumbUrlById } from '../utils/mediaUtils';
import { useDispatch, useSelector } from 'react-redux';
import { bconfirm } from '../utils/ConfirmAlert';
import { archiveGame, cloneGame, deleteGame, unpublishGame } from '../../actions/gamesActions';
import { GAME_RIGHTS, USER_ROLE } from '../../utils/constants';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    href='#'
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    {children}
    <i className='fa fa-bars'></i>
  </a>
));

export default function GameItem({ game }) {
  const user = useSelector((state) => state.auth.user);

  const userRights = getUserRightsOnGame(game, user);
  const logoUrl = getThumbUrlById(game.logoId, true);
  const dispatch = useDispatch();
  const handleCloneGame = async (gameId) => {
    const doClone = await bconfirm('Are you sure you want to Clone this Game?');
    if (doClone) {
      dispatch(cloneGame(gameId));
    }
  };
  const handleUnpublishGame = async (gameId) => {
    const doClone = await bconfirm('Are you sure you want to Unpublish this Game?');
    if (doClone) {
      dispatch(unpublishGame(gameId));
    }
  };

  const handleDeleteGame = async (gameId) => {
    const doDelete = await bconfirm('Operation cannot be Undone. All game data will be removed. Do you want to proceed?');
    if (doDelete) {
      dispatch(deleteGame(gameId));
    }
  };

  const handleArchiveGame = async (gameId) => {
    const doArchive = await bconfirm('Operation cannot be Undone. Do you want to proceed?');
    if (doArchive) {
      dispatch(archiveGame(gameId));
    }
  };
  if (!userRights || userRights.length === 0) {
    return <></>;
  }
  return (
    <Card key={game.id} className='shadow-sm'>
      <Card.Header className='d-flex bg-white'>
        <span className='material-icons-outlined m-icon' title={game.publicVersionId ? 'Published' : 'Not Published'}>
          {game.publicVersionId ? 'visibility' : 'visibility_off'}
        </span>
        <span className='ml-2 font-weight-bold'>{game.title}</span>
        <Row className='ml-auto mr-1'>
          <Dropdown>
            <Dropdown.Toggle drop='left' variant='primary' id='dropdown-basic' as={CustomToggle}></Dropdown.Toggle>
            <Dropdown.Menu>
              {userRights.includes('clone') && (
                <Dropdown.Item
                  onSelect={() => {
                    handleCloneGame(game.id);
                  }}>
                  <span className='material-icons-outlined m-icon'>content_copy</span> Clone Game
                </Dropdown.Item>
              )}
              {!game.publicVersionId && userRights.includes('manage') && (
                <Dropdown.Item
                  onSelect={() => {
                    handleDeleteGame(game.id);
                  }}>
                  <span className='material-icons-outlined m-icon'>delete_outline</span> Delete Game
                </Dropdown.Item>
              )}
              {game.publicVersionId && userRights.includes('manage') && (
                <Dropdown.Item
                  eventKey='3'
                  onSelect={() => {
                    handleUnpublishGame(game.id);
                  }}>
                  <span className='material-icons-outlined m-icon'>visibility_off</span> Unpublish Game
                </Dropdown.Item>
              )}
              {userRights.includes('manage') && (
                <Dropdown.Item
                  eventKey='3'
                  onSelect={() => {
                    handleArchiveGame(game.id);
                  }}>
                  <span className='material-icons-outlined m-icon'>archive</span> Archive Game
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={'auto'}>
            <Image src={logoUrl} thumbnail width={120} />
          </Col>
          <Col className='d-flex flex-column justify-content-between'>
            <Card.Text>{shortenText(game.description, 400, 'No description')}</Card.Text>
            <div className='border-top' style={{ marginTop: 0, paddingTop: 10, fontSize: 14, color: 'gray', textAlign: 'right' }}>
              By <strong>{game.userId?._id === user?.id ? 'me' : game.userId?.name}</strong> on {format(new Date(game.createdAt), 'MMM do, yyyy')}. Last updated on{' '}
              {/* {format(new Date(game.updatedAt), 'MMM do, yyyy')} */}
              {format(new Date(game.updatedAt), 'PPpp')}
            </div>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className='d-flex p-2 pr-2 flex-row justify-content-between'>
        {userRights.includes('edit') && (
          <div>
            <LinkContainer to={`/games/${game.id}`}>
              <Button className={'mr-1'}>
                <i className='fas fa-magic'></i> Design
              </Button>
            </LinkContainer>
            <LinkContainer variant='warning' to={`/games/edit/${game.id}`}>
              <Button className={'mr-1'}>
                <i className='fas fa-cog'></i> Settings
              </Button>
            </LinkContainer>
          </div>
        )}
        {!userRights.includes('edit') && userRights.includes('view') && (
          <LinkContainer to={`/games/${game.id}`}>
            <Button className={'mr-1'}>
              <i className='fas fa-search'></i> View inside
            </Button>
          </LinkContainer>
        )}
        {userRights.includes('manage') && (
          <div>
            <LinkContainer to={`/active-games/${game.id}`}>
              <Button variant='outline-info' className={'mr-1'}>
                <i className='fas fa-cog'></i> Gameplay
              </Button>
            </LinkContainer>

            <LinkContainer to={`/analytics/${game.id}`}>
              <Button variant='outline-secondary' className={'mr-1'}>
                <>
                  <span className='material-icons-outlined m-icon'>donut_small</span> Analytics
                </>
              </Button>
            </LinkContainer>
          </div>
        )}
      </Card.Footer>
    </Card>
  );
}
