import gamesApi from '../apis/gamesApi';
import history from '../history';
import { FETCH_GAME_MISSIONS, FETCH_MISSION, EDIT_MISSION, CREATE_MISSION, DELETE_MISSION } from './types';
import { displayError, displaySuccess } from '../components/utils/notifications';

export const createMission = (gameId, formValues) => async (dispatch) => {
  // console.log('Creating mission ', formValues)
  return new Promise((resolve, reject) => {
    gamesApi
      .post(`/api/design/missions?gameId=${gameId}`, { ...formValues, gameId })
      .then((response) => {
        dispatch({ type: CREATE_MISSION, payload: response.data });
        displaySuccess(`Mission ${response.data.name} created`);
        resolve(response.data);
        //history.push(`/games/${gameId}`);
      })
      .catch((err) => {
        displayError(err, `Error creating mission`);
        reject();
      });
  });
};

export const fetchGameMissions = (gameId) => async (dispatch) => {
  if (gameId) {
    return new Promise((resolve, reject) => {
      gamesApi
        .get(`/api/design/missions?gameId=${gameId}`)
        .then((response) => {
          dispatch({ type: FETCH_GAME_MISSIONS, payload: response.data });
          resolve();
        })
        .catch((err) => {
          displayError(err, `Error retrieving game missions`);
          reject();
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      dispatch({ type: FETCH_GAME_MISSIONS, payload: [] });
      displayError(null, `No gameId id in fetchGameMissions`);
      reject();
    });
  }
};

export const fetchMission = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .get(`/api/design/missions/${id}`)
      .then((response) => {
        dispatch({ type: FETCH_MISSION, payload: response.data });
        resolve();
      })
      .catch((err) => {
        displayError(err, `Error retrieving mission data for id ${id}`);
        reject();
      });
  });
};
export const editMission = (id, formValues) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .patch(`/api/design/missions/${id}`, formValues)
      .then((response) => {
        dispatch({ type: EDIT_MISSION, payload: response.data });
        displaySuccess(`Mission ${response.data.name} saved`);
        resolve();
        // history.push(`/games/${gameId}`);
      })
      .catch((err) => {
        displayError(err, `Error saving mission ${id}`);
        reject();
      });
  });
};
export const deleteMission = (id, redirectUrl) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    gamesApi
      .delete(`/api/design/missions/${id}`)
      .then((response) => {
        dispatch({ type: DELETE_MISSION, payload: id });
        displaySuccess(`Mission deleted`, `Mission ${id} was deleted`);
        resolve();
        if (redirectUrl) {
          // ??????????????
          history.push(redirectUrl);
        }
      })
      .catch((err) => {
        displayError(err, `Error deleting mission ${id}`);
        reject();
      });
  });
};
