import React, { useEffect } from 'react';
//import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import { connect } from 'react-redux';
import MainContent from '../layouts/MainContent';
//import history from "../../history";

import qs from 'qs';
import { withRouter } from 'react-router-dom';
import './spinner.css';
import { useState } from 'react';

import authapi from '../../apis/authapi';
import Form from 'react-bootstrap/Form';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';

const ForgotChange = ({ location }) => {
  const [changeResult, setChangeResult] = useState(null);
  const [errors, setErrors] = useState({});
  const [p1, setP1] = useState('');
  const [p2, setP2] = useState('');

  const CHANGE_SUCCESS = 'CHANGE_SUCCESS';
  const CHANGE_FAILED = 'CHANGE_FAILED';
  const VALIDATION_FAILED = 'VALIDATION_FAILED';
  const VALIDATION_EXPIRED = 'VALIDATION_EXPIRED';

  const CHANGE_GENERALERROR = 'CHANGE_GENERALERROR';

  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  const mac = queryString.mac;
  const msg = queryString.msg;

  const changedComponent = (
    <>
      <p>Η αλλαγή του κωδικού ολοκληρώθηκε.</p>
      <p>Password has been set.</p>
    </>
  );
  const expiredValidationComponent = (
    <>
      <p>Η διεύθυνση που χρησιμοποιήσατε για επανάθεση έχει λήξει. Παρακαλούμε ξανακάνετε όλη τη διαδικασία από την αρχή..</p>
      <p>The address you used has expired. Please restart the reset password procedure.</p>
    </>
  );
  const failedValidationComponent = (
    <>
      <p>Τα στοιχεία του συνδέσμου δεν είναι έγκυρα.</p>
      <p>The data on the password reset link are not valid.</p>
    </>
  );
  const failedComponent = (
    <>
      <p>Γενικό σφάλμα (2).</p>
      <p>General error (2).</p>
    </>
  );

  const generalErrorComponent = (
    <>
      <p>Γενικό σφάλμα (1).</p>
      <p>General error (1).</p>
    </>
  );
  const onP1Change = (e) => {
    setP1(e.target.value);
  };
  const onP2Change = (e) => {
    setP2(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    authapi
      .post('/api/users/forgotchange', { p1, p2, mac, msg })
      .then((response) => {
        // console.log(response.data);
        if (response && response.data && response.data.success === true) {
          setChangeResult(CHANGE_SUCCESS);
        } else {
          if (response.data.result === VALIDATION_EXPIRED || response.data.result === VALIDATION_FAILED) {
            setChangeResult(response.data.result);
          } else {
            setChangeResult(CHANGE_FAILED);
          }
        }
      })
      .catch((err) => {
        // console.log('forgot general error 2', err, err.response);
        if (err && err.response && err.response.data && err.response.data.success === false) {
          setErrors(err.response.data);
        }
        //setSendLinkResult(SENDLINK_GENERALERROR);
      });
  };

  const formComponent = (
    <>
      <p>Δηλώστε το νέο σας κωδικό πρόσβασης.</p>
      <p>Enter your new password.</p>
      <p className={'red-text'}>{errors.generalError}</p>
      <Form noValidate onSubmit={onSubmit}>
        <Form.Group controlId='p1'>
          <Form.Label>Password</Form.Label>
          <Form.Control onChange={onP1Change} value={p1} error={errors.password} type='password' className={errors.password ? 'invalid' : ''} placeholder='*****' />
          <Form.Text className='text-muted'>At least 8 characters.</Form.Text>
          <Form.Text className='text-muted'>
            <span className={'red-text'}>{errors.p1}</span>
          </Form.Text>
        </Form.Group>
        <Form.Group controlId='p2'>
          <Form.Label>Confirm password</Form.Label>
          <Form.Control onChange={onP2Change} value={p2} error={errors.password2} type='password' className={errors.password2 ? 'invalid' : ''} placeholder='*****' />
          <Form.Text className='text-muted'>
            <span className={'red-text'}>{errors.p2}</span>
          </Form.Text>
        </Form.Group>
        <div className='row'>
          <div className='col-md-12'>
            <div className='form-group'>
              <button type='submit' className='btn btn-primary btn-block'>
                {' '}
                Υποβολή / Submit
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );

  // <LinkContainer to={`/login`}>
  //     <a href="/login"> Sign in</a>
  // </LinkContainer>

  let msgComponent = null;

  if (changeResult === null) {
    msgComponent = formComponent;
  } else if (changeResult === CHANGE_SUCCESS) {
    msgComponent = changedComponent;
  } else if (changeResult === VALIDATION_EXPIRED) {
    msgComponent = expiredValidationComponent;
  } else if (changeResult === VALIDATION_FAILED) {
    msgComponent = failedValidationComponent;
  } else if (changeResult === CHANGE_FAILED) {
    msgComponent = failedComponent;
  } else if (changeResult === CHANGE_GENERALERROR) {
    msgComponent = generalErrorComponent;
  }
  //http://localhost:3000/confirmemail?mac=24ff69aaef4ff8cc5cee0f11b3f710806bebb0ac4d0b634a0862247482858765&msg=sk%2B55%40karasavvidis.gr_00_1643971404555
  //  validationResult: {validationResult}
  return (
    <>
      <MainContent isAuthView header='' hasSideBar={false}>
        <div className='row justify-content-md-center'>
          <div className='row justify-content-md-center logo-container'>
            <div className='mb-3 text-center'>
              <img width='400' src={`${process.env.PUBLIC_URL}/resources/images/rollick_icon_transparrent.svg`} className='' alt='Rollick logo' />
            </div>
          </div>

          <div className='card col-md-3'>
            <article className='card-body'>{msgComponent}</article>
          </div>
        </div>
      </MainContent>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    //isAuthenticated: state.auth.isAuthenticated
  };
};

export default withRouter(connect(mapStateToProps, null)(ForgotChange));
