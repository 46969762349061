import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, formValueSelector } from 'redux-form';
import renderCheckbox from './renderers/renderCheckbox';
import renderInput from './renderers/renderInput';
import { INTERACTION_FORM_NAME as FORM_NAME } from './formTypes';
import { connect } from 'react-redux';
import renderCheckbox2 from './renderers/renderCheckbox2';

export default function InteractionExtras({ fieldNameBase, typeWriting, directions, isDirection }) {
  return (
    <>
      <Row>
        {typeWriting && (
          <Col lg={3}>
            <Field placementFix asCol={false} component={renderCheckbox2} name={`${fieldNameBase ? fieldNameBase + '.' : ''}typeWrite`} type='checkbox' label='Show with Typing Effect' />
          </Col>
        )}
        {directions && (
          <Col lg={3}>
            <Field
              placementFix
              asCol={false}
              component={renderCheckbox2}
              name={`${fieldNameBase ? fieldNameBase + '.' : ''}direction.isDirection`}
              type='checkbox'
              label={isDirection ? 'Show in directions with title:' : 'Show in directions'}
            />
          </Col>
        )}

        {directions && isDirection && (
          <Col lg={6}>
            <Field name={`${fieldNameBase ? fieldNameBase + '.' : ''}direction.title`} label='Direction title' hideLabel={true} component={renderInput} />
          </Col>
        )}
      </Row>
    </>
  );
}

InteractionExtras = connect((state, ownProps) => {
  const selector = formValueSelector(FORM_NAME);
  return {
    isDirection: selector(state, `${ownProps.fieldNameBase ? ownProps.fieldNameBase + '.' : ''}direction.isDirection`),
  };
})(InteractionExtras);
