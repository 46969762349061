import React, { useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { createMedia } from '../../actions/mediasActions';
import { displayError } from '../utils/notifications';
import { camelCase } from 'lodash';

const maxMediaSize = {
  image: 0.5,
  video: 8,
  audio: 5,
};

const MediaUpload = ({ gameId, dispatch, createMedia }) => {
  const fileInput = useRef(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (fileInput.current.files.length > 0) {
      const formData = new FormData();
      let filesToUpload = 0;
      Object.keys(fileInput.current.files).forEach((key) => {
        const file = fileInput.current.files[key];
        let mediaType = 'image';
        try {
          mediaType = file.type.split('/')[0];
        } catch (err) {}
        const maxSize = maxMediaSize[mediaType] ?? 5; //5mb
        if (file.size <= maxSize * 1000000) {
          filesToUpload += 1;
          formData.append(key, new Blob([file], { type: file.type }), file.name || 'file');
        } else {
          displayError(null, 'Too large file!', `${camelCase(mediaType)} "${file.name}" is > ${maxSize}Mb`);
        }
      });
      if (filesToUpload > 0) {
        dispatch(createMedia(formData, gameId))
          .then(() => {
            // console.log('Finished upload');
          })
          .catch((e) => {
            /* do nothing. An error toastr should have been displayed */
          });
      }
    }
  };
  const clearSelection = (event) => {
    event.preventDefault();
    //fileInput.current.removeFiles()
  };
  if (!gameId) {
    return <p>No game id</p>;
  }
  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <h3>Upload media</h3>
          <Container fluid>
            <Row>
              <Col>
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Row>
                    <Form.Group as={Col} xs='auto'>
                      <Form.File accept='image/png, image/jpeg, audio/*, video/*' multiple ref={fileInput} />
                    </Form.Group>
                    <Form.Group as={Col} xs='auto'>
                      <Button variant='primary' type='submit'>
                        Upload
                      </Button>
                    </Form.Group>
                    <Form.Group as={Col} xs='auto'>
                      <Button variant='secondary' onClick={clearSelection}>
                        Clear selection
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

//export default MediaUpload;

// const mapStateToProps = (state, ownProps) => {
//     return {game:state.games[ownProps.gameidToEdit]};
// };
const mapStateToProps = (state, ownProps) => {
  return {};
};
export default connect(mapStateToProps, (dispatch) => ({ dispatch, createMedia: createMedia }))(MediaUpload);
