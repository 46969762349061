import React, { useEffect } from 'react';
//import LinkContainer from "react-router-bootstrap/lib/LinkContainer";
import { connect } from 'react-redux';
import MainContent from '../layouts/MainContent';
//import history from "../../history";

import qs from 'qs';
import { withRouter } from 'react-router-dom';
import './spinner.css';
import { useState } from 'react';

import authapi from '../../apis/authapi';
import LinkContainer from 'react-router-bootstrap/lib/LinkContainer';
import Button from 'react-bootstrap/Button';

const RegisterConfirm = ({ location }) => {
  const [validationResult, setValidationResult] = useState(null);
  const VALIDATION_SUCCESS = 'CONFIRMATION_SUCCESS';
  const VALIDATION_FAILED = 'VALIDATION_FAILED';
  const VALIDATION_EXPIRED = 'VALIDATION_EXPIRED';

  const VALIDATION_GENERALERROR = 'VALIDATION_GENERALERROR';

  const queryString = qs.parse(location.search, { ignoreQueryPrefix: true });
  const mac = queryString.mac;
  const msg = queryString.msg;
  useEffect(() => {
    authapi
      .post('/api/users/confirm', { mac, msg })
      .then((response) => {
        // console.log('validation general error', response.data);

        setValidationResult(response.data.result);
      })
      .catch((err) => {
        // console.log('validation general error', err);
        setValidationResult(VALIDATION_GENERALERROR);
      });
  }, []);

  const missingDataComponent = (
    <>
      <p>Αυτή η διεύθυνση δεν είναι έγκυρη</p>
      <p>This address is not valid</p>
    </>
  );
  const expiredComponent = (
    <>
      <p>
        Αυτή η διεύθυνση έχει λήξει. Παρακαλώ χρησιμοποιήστε τη λειτουργία{' '}
        <LinkContainer to={`/resend`}>
          <a href={''}>επαναποστολής μηνύματος επιβεβαίωσης</a>
        </LinkContainer>
        .
      </p>
      <p>
        This address is no longer valid. Please{' '}
        <LinkContainer to={`/resend`}>
          <a hreg={''}>resend the confirmation email</a>
        </LinkContainer>
        .
      </p>
    </>
  );
  const confirmedComponent = (
    <>
      <p>Επιτυχής επιβεβαίωση της διεύθυνσής σας. Μπορείτε να προχωρήσετε σε σύνδεση στην εφαρμογή.</p>
      <p>You have successfully confirmed your account. You may proceed to login in the app.</p>
    </>
  );
  const failedComponent = (
    <>
      <p>Δεν ήταν δυνατή η επιβεβαίωση της διεύθυνσης. Παρακαλώ ελέγξτε τη διεύθυνση που ακολουθήσατε.</p>
      <p>The validation has failed. Please check the link you have followed.</p>
    </>
  );

  const generalErrorComponent = (
    <>
      <p>Γενικό σφάλμα.</p>
      <p>General error.</p>
    </>
  );

  const validatingComponent = (
    <>
      <div className='spinnerwrapper'>
        <i className='spinner'></i>
      </div>
      Έλεγχος στοιχείων
      <br />
      Validating data
    </>
  );

  // <LinkContainer to={`/login`}>
  //     <a href="/login"> Sign in</a>
  // </LinkContainer>

  let msgComponent = null;

  if (!mac || !msg) {
    msgComponent = missingDataComponent;
  } else if (validationResult === null) {
    msgComponent = validatingComponent;
  } else if (validationResult === VALIDATION_SUCCESS) {
    msgComponent = confirmedComponent;
  } else if (validationResult === VALIDATION_FAILED) {
    msgComponent = failedComponent;
  } else if (validationResult === VALIDATION_EXPIRED) {
    msgComponent = expiredComponent;
  } else if (validationResult === VALIDATION_GENERALERROR) {
    msgComponent = generalErrorComponent;
  }
  //http://localhost:3000/confirmemail?mac=24ff69aaef4ff8cc5cee0f11b3f710806bebb0ac4d0b634a0862247482858765&msg=sk%2B55%40karasavvidis.gr_00_1643971404555
  //  validationResult: {validationResult}
  return (
    <>
      <MainContent header='' hasSideBar={false}>
        <div className='row justify-content-md-center'>
          <div className='card col-md-3'>
            <article className='card-body'>{msgComponent}</article>
          </div>
        </div>
      </MainContent>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    //isAuthenticated: state.auth.isAuthenticated
  };
};

export default withRouter(connect(mapStateToProps, null)(RegisterConfirm));
