import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { getProperty, hasProperty } from 'dot-prop';

import { formatDate, getTotalCount, extractData, exportToCsv } from '../../utils/utils';

const ConversationsSummaryWidget = ({ publishedGameVersion, analytics }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [conversationData, setConversationData] = useState({ conversationId: null, userState: null });

  const extractConversationChat = (userState, conversationId) => {
    const spec = publishedGameVersion.game.spec;
    const chat = [];

    const getCharacterInfo = (id) => {
      const npc = spec.resources.characters.find((ch) => ch.id == id);
      if (!npc) {
        return { name: 'Game Master', avatarUri: null, npc: true };
      }
      return { name: npc.name, avatarUri: npc.avatarUri, npc: true };
    };

    const getPlayerInfo = () => {
      return {
        name: userState.avatar.name || 'Player',
        avatarUri: getProperty(userState, `avatar.photo.uri`) || null,
        npc: false,
      };
    };

    const getPlayerResponse = (npcStmts, curIdx) => {
      if (curIdx < 0 || curIdx >= npcStmts.length) {
        return null;
      }
      const stmt = npcStmts[curIdx];
      let option;
      if (stmt.repliedWith) {
        option = stmt.options.find((opt) => opt.id === stmt.repliedWith);
      } else if (curIdx < npcStmts.length - 1) {
        const nextStmt = npcStmts[curIdx + 1];
        option = stmt.options.find((opt) => opt.do.actionName == 'sayStatement' && opt.do.actionArgs.objectId === nextStmt.id);
      }
      if (!option) return null;
      return {
        body: { msg: { txt: option.actionLabel } },
        speaker: getPlayerInfo(),
        when: stmt.when,
      };
    };

    const conversation = (spec.resources.conversations ?? []).find((cv) => cv.id === conversationId);

    if (!conversation) {
      return chat;
    }

    const seenLog = getProperty(userState, `has.seen.conversation.${conversationId}.statement`) ?? {};

    const npcStmts = [];
    for (const [key, value] of Object.entries(seenLog)) {
      const { id, body, options } = conversation?.statements[key];
      npcStmts.push({ id, body, options, ...value });
    }

    npcStmts.forEach((stmt, idx) => {
      chat.push({
        body: stmt.body,
        speaker: getCharacterInfo(stmt.spokenBy),
        when: stmt.when,
      });
      const response = getPlayerResponse(npcStmts, idx);
      if (response) {
        chat.push(response);
      }
    });
    return chat;
  };

  const renderConversationLine = (cLine) => {
    // console.log({ cLine });
    const {
      body: {
        msg: { txt },
      },
      speaker,
      when,
    } = cLine;
    return (
      <div key={when} className={`conversation-chat__message conversation-chat__message${speaker.npc ? '--npc' : '--player'}`}>
        <div className='conversation-chat__speaker-name'>
          {speaker.name}

          <span className='ml-2 conversation-chat__timestamp'>{formatDate(when)}</span>
        </div>
        <div className='conversation-chat__bubble' dangerouslySetInnerHTML={{ __html: txt }} />
      </div>
    );
  };

  const exportConversationStats = () => {
    const { conversations } = publishedGameVersion?.game?.spec?.resources;
    const headers = ['Conversation', 'Started', 'Completed'];
    const data = conversations.map((c) => {
      const row = [c.title];
      row.push(getTotalCount(analytics, `has.started.conversation.${c.id}`));
      row.push(getTotalCount(analytics, `has.completed.conversation.${c.id}`));
      return row;
    });

    // console.log({ headers, data });
    exportToCsv(`ConversationStats.csv`, [[...headers], ...data]);
  };

  return (
    <>
      <h5 className='d-flex flex-row justify-content-between'>
        Conversation Stats
        <Button style={{ float: 'right' }} onClick={exportConversationStats}>
          <i className='fas fa-download mr-2' />
          CSV
        </Button>
      </h5>
      <ul className='list-group mt-3'>
        {publishedGameVersion.game.spec.resources.conversations.map((c) => {
          return (
            <li key={c.id} className='list-group-item d-flex justify-content-between align-items-center'>
              <a
                className='text-primary'
                onClick={() => {
                  setModalOpen(true);
                  setModalData(c);
                }}>
                {c.title}
              </a>

              <small className='font-sm text-right'>
                {'started '}
                <strong>{getTotalCount(analytics, `has.started.conversation.${c.id}`)}</strong>
                {' / Completed: '}
                <strong>{getTotalCount(analytics, `has.completed.conversation.${c.id}`)}</strong>
              </small>
            </li>
          );
        })}
      </ul>

      <Modal
        size='lg'
        show={modalOpen && !conversationData.conversationId}
        onHide={() => {
          setModalOpen(false);
        }}
        className='analytics'>
        <Modal.Header closeButton>
          <Modal.Title>Conversation: {modalData && modalData.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {modalData && (
            <>
              <table className='table table-bordered table-striped'>
                <thead>
                  <tr>
                    <th>Player</th>
                    <th>Completed</th>
                    <th>Date and time</th>
                  </tr>
                </thead>

                <tbody>
                  {extractData(analytics, `has.started.conversation.${modalData.id}`).map((userState) => {
                    return (
                      <tr>
                        <td>
                          <a
                            className='text-primary'
                            onClick={() => {
                              setConversationData({
                                userState,
                                conversationId: modalData.id,
                              });
                            }}>
                            {(userState.avatar && userState.avatar.name) || '-'}
                          </a>
                        </td>
                        <td>{hasProperty(userState, `has.completed.conversation.${modalData.id}`) ? 'Yes' : 'No'}</td>
                        <td>
                          {formatDate(getProperty(userState, `has.started.conversation.${modalData.id}.when`) || getProperty(userState, `has.started.conversation.${modalData.id}.current.when`))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        size='lg'
        show={!!conversationData.conversationId}
        onHide={() => {
          setConversationData({ conversationId: null, userState: null });
        }}
        className='analytics'>
        <Modal.Header closeButton>
          <Modal.Title>Conversation chat: {conversationData.conversation && conversationData.conversation.id}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='conversation-chat'>
            {conversationData.conversationId && extractConversationChat(conversationData.userState, conversationData.conversationId).map((convLine) => renderConversationLine(convLine))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConversationsSummaryWidget;
